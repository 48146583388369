<template>
  <v-col class="regads mt-2">
    <v-col class="shades white" :class="$vuetify.breakpoint.mdAndUp ? 'pa-10' : 'pa-6'">
      <v-row class="mb-5">
        <v-col cols="12" md="12">
          <div class="header mb-2">
            희망하는 <span class="font-weight-bold text-decoration-underline">구독 플랜</span>을 선택해 주세요.
          </div>

          <div style="color: #484848">광고 시작 전에는 언제든지 변경하실 수 있습니다.</div>
        </v-col>
      </v-row>

      <v-row class="mb-6" style="gap: 8px">
        <v-col cols="12" md="" @click="selectRadio(1)">
          <div class="radio-box" :class="{ selected: form.plan === 1 }">
            <div class="badge sm mb-3">1개월</div>
            <div class="radio-box-header mb-4">
              <v-row>
                <div>월 15만원</div>
              </v-row>
            </div>

            <div class="radio-box-content">
              <div class="mb-3">
                <v-icon size="12" class="mr-1">mdi-check-circle</v-icon
                ><span>모집인원 <span class="font-weight-bold">무제한</span></span>
              </div>
              <div class="mb-3">
                <v-icon size="12" class="mr-1">mdi-check-circle</v-icon
                ><span>월 보장인원 <span class="font-weight-bold">8명</span></span>
              </div>
              <div class="mb-3">
                <v-icon size="12" class="mr-1">mdi-check-circle</v-icon
                ><span>보장인원 미충족 시 <span class="font-weight-bold">기간 연장</span></span>
              </div>
            </div>
            <div class="mt-auto VAT">VAT 별도</div>
          </div>
        </v-col>
        <v-col cols="12" md="" @click="selectRadio(6)">
          <div class="radio-box" :class="{ selected: form.plan === 6 }">
            <div class="emphasize-badge">BEST</div>
            <div class="badge sm mb-3">6개월</div>
            <div class="radio-box-header mb-4">
              <v-row>
                <div>월 13.5만원<span class="ml-2 original-price">150,000원</span></div>
              </v-row>
            </div>

            <div class="radio-box-content">
              <div class="mb-3">
                <v-icon size="12" class="mr-1">mdi-check-circle</v-icon
                ><span>모집인원 <span class="font-weight-bold">무제한</span></span>
              </div>
              <div class="mb-3">
                <v-icon size="12" class="mr-1">mdi-check-circle</v-icon
                ><span>총 보장인원 <span class="font-weight-bold">48명</span></span>
              </div>
              <div class="mb-3">
                <v-icon size="12" class="mr-1">mdi-check-circle</v-icon
                ><span>보장인원 미충족 시 <span class="font-weight-bold">기간 연장</span></span>
              </div>
              <div class="mb-3">
                <v-icon size="12" class="mr-1">mdi-check-circle</v-icon
                ><span>맞춤 <span class="font-weight-bold">키워드 컨설팅 1회</span> 제공</span>
              </div>
            </div>
            <div class="mt-auto VAT">VAT 별도</div>
          </div>
        </v-col>
        <v-col cols="12" md="" @click="selectRadio(12)">
          <div class="radio-box" :class="{ selected: form.plan === 12 }">
            <div class="badge lg mb-3">12개월</div>
            <div class="radio-box-header mb-4">
              <v-row>
                <div>월 12만원 <span class="ml-2 original-price">150,000원</span></div>
              </v-row>
            </div>

            <div class="radio-box-content">
              <div class="mb-3">
                <v-icon size="12" class="mr-1">mdi-check-circle</v-icon
                ><span>모집인원 <span class="font-weight-bold">무제한</span></span>
              </div>
              <div class="mb-3">
                <v-icon size="12" class="mr-1">mdi-check-circle</v-icon
                ><span>월 보장인원 <span class="font-weight-bold">96명</span></span>
              </div>
              <div class="mb-3">
                <v-icon size="12" class="mr-1">mdi-check-circle</v-icon
                ><span>보장인원 미충족 시 <span class="font-weight-bold">기간 연장</span></span>
              </div>
              <div class="mb-3">
                <v-icon size="12" class="mr-1">mdi-check-circle</v-icon
                ><span>맞춤 <span class="font-weight-bold">키워드 컨설팅 1회</span> 제공</span>
              </div>
              <div class="mb-3">
                <v-icon size="12" class="mr-1">mdi-check-circle</v-icon
                ><span>광고 <span class="font-weight-bold">일시중지 2회</span> 제공 (30일)</span>
              </div>
            </div>
            <div class="mt-auto VAT">VAT 별도</div>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-col>
</template>

<script>
export default {
  name: 'Page4',
  components: {},
  props: ['adsData', 'option'],
  data: function () {
    return {
      form: {
        plan: this.adsData.plan,
      },
    };
  },
  watch: {
    adsData: {
      handler(newValue) {
        this.form.plan = newValue.plan || 1;
      },
    },
  },
  methods: {
    selectRadio(plan) {
      this.form.plan = plan;
      this.form.step = 4;
      this.$emit('updateEvent', this.form);
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  font-size: 18px;
  font-weight: 400;
  color: #000;
}

.info-header {
  font-size: 14px;
  font-weight: 700;
  color: #000;
}

.radio-box {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 280px;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 16px;
  border: 1px solid var(--disabled-color);
  cursor: pointer;
}

.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid var(--primary-light-color);
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 700;
  color: var(--primary-light-color);
}

.sm {
  width: 54px;
}

.lg {
  width: 62px;
}

.emphasize-badge {
  position: absolute;
  width: 55px;
  height: 27px;
  top: -10px;
  right: 20px;
  color: var(--white-two-color);
  background-color: var(--primary-light-color);
  border-radius: 100px;
  padding: 6px 12px;
  font-size: 12px;
}

.radio-box-header {
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  font-weight: 700;
  gap: 8px;
  color: #000;
}

.radio-box-content {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 500;
  color: var(--default-2-color);
  gap: 0px;
}

.original-price {
  font-size: 14px;
  color: var(--disabled-dark-color);
  text-decoration: line-through;
}

.VAT {
  font-size: 12px;
  color: var(--default-1-color);
}

.selected {
  background-color: rgba(234, 42, 58, 0.05);
  i {
    color: #000;
  }

  .radio-box-header {
    color: #000;
  }
}
</style>
